@media (min-width: 700px) {
  .application-title {
    height: 150px;
  }
}

@media (max-width: 700px) {
  .application-title {
    height: 200px;
  }
}

.app-list {
  height: 135px;
}

.list-group-item {
  height: 45px;
}