nav, footer {
  background-color: #D6FF58;
}

@media (min-width: 420px) {
  footer {
    height: 75px;
    text-align: center;
  }
}

@media (max-width: 419px) {
  footer {
    height: 100px;
    text-align: center;
  }
}

a {
  text-decoration: none;
}


.nav-link, .navbar-brand, footer, .report-all, footer a {
  color: black;
}

.nav-link:hover, .navbar-brand:hover, .footer-info a:hover {
  color: white;
}

.report-all:hover {
  color: grey;
}

.badge {
  background-color: #FF8856;
  color: white;
}

.top-header {
  height: 300px;
  background-size: cover;
  background-image: url(./img/top_photo.jpg);
}

.text-body {
  min-height: 1000px;
}

.footer-about {
  visibility: visible;
}

.footer-font {
  vertical-align: inherit;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer-info {
  margin-bottom: 10px;
}

footer a {
  margin: 0 10px;
}

.privacy-policy {
  margin-top: 20px;
}

textarea.form-control {
  height: 200px;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.error-message {
  color: red;
}